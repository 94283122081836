





























import Breadcrumbs from "@monorepo/uikit/src/components/common/Breadcrumbs.vue";
import ScrollPanel from "@monorepo/uikit/src/components/tableViews/ScrollPanel.vue";
import { defineComponent, ref } from "@vue/composition-api";
import { module as StorageCatalogView } from "@monorepo/catalog/src/store/modules/storageCatalogView";
import useInitTableStoreModule from "@monorepo/utils/src/store/composables/useInitTableStoreModule";
import { mapGetters, Module } from "vuex";
import { breadcrumbs } from "@monorepo/catalog/src/views/StorageView/constants/breadcrumbs";
import { viewTitle, viewUniqKey } from "@monorepo/utils/src/variables/projectsData/storageView/viewTitle";
import useGetTableLibs from "@monorepo/catalog/src/views/StorageView/composables/useGetTableLibs";

const SECTION_NAME = "storageCatalogView";

export default defineComponent({
  name: "StorageView",
  components: {
    ScrollPanel,
    Breadcrumbs,
    StorageTree: () =>
      import(
        /* webpackChunkName: "StorageTree" */
        "@monorepo/catalog/src/views/StorageView/components/StorageTree.vue"
      ),
  },
  data() {
    return {
      breadcrumbs,
      viewTitle,
      viewUniqKey,
    };
  },
  computed: {
    ...mapGetters(SECTION_NAME, ["isTableLoading"]),
  },
  setup(props, { root }) {
    const sectionName = ref(SECTION_NAME); // sectionName === store module name

    useInitTableStoreModule(root, SECTION_NAME, StorageCatalogView as Module<unknown, unknown>);
    useGetTableLibs(root);

    return {
      sectionName,
    };
  },
});
