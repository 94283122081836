import axios from "axios";
import { Module } from "vuex";
import { IRootState } from "@monorepo/catalog/src/store";
import { QUERY_PATH } from "@monorepo/utils/src/api/queryPath";
import { Sections } from "@monorepo/utils/src/types/cellsSections";
import { checkExistLibrary, getFullPath, getQuery } from "@monorepo/utils/src/api/utils";
import { mutations as baseMutations } from "@monorepo/utils/src/store/modules/mutations";
import { actions as baseActions } from "@monorepo/utils/src/store/modules/actions";
import { getters as baseGetters } from "@monorepo/utils/src/store/modules/getters";
import { showNotification } from "@monorepo/utils/src/eventBus/utils/showNotification";
import { NOTIFICATION_STATUS } from "@monorepo/utils/src/eventBus/types/notification";

interface ITableState {
  section?: Sections;
  totalLength: number;
  infiniteId: string;
  libraries: {
    types: any[];
    archives: any[];
  };
  isTableLoading: boolean;
}

type error = {
  response?: { data?: { message?: string; error?: { text: string } } };
};

export const module: Module<ITableState, IRootState> = {
  namespaced: true,
  state: (): ITableState => ({
    section: Sections.STORAGE_CATALOG,
    infiniteId: new Date().toString(),
    totalLength: 0,
    libraries: {
      types: [],
      archives: [],
    },
    isTableLoading: false,
  }),
  mutations: {
    ...baseMutations,
    addTypesLib(state: ITableState, payload: any[]) {
      state.libraries.types =
        payload.map((type) => {
          return { ...type, value: type.code };
        }) || [];
    },
    addArchivesLib(state: ITableState, payload: any[]) {
      state.libraries.archives =
        payload.map((archive) => {
          return { ...archive, value: archive.id, title: archive.shortName };
        }) || [];
    },
    setTableLoading(state: ITableState, payload: boolean) {
      state.isTableLoading = payload;
    },
  },
  actions: {
    ...baseActions,
    getTypes({ commit, state }) {
      checkExistLibrary(state.libraries.types, async () => {
        const { data } = await getQuery<any[]>(QUERY_PATH.GET_STORAGE_TYPES);
        commit("addTypesLib", data || []);
      });
    },
    async getArchivesData({ commit }) {
      const { data } = await getQuery<any[]>(`${QUERY_PATH.GET_CATALOG_ARCHIVE_ALL}`);

      if (data !== null) {
        commit("addArchivesLib", data || []);
      }
    },
    async getTreeData(info, params?: { parentNodeId?: string; archiveId?: string }) {
      const { data } = await getQuery<any[]>(`${QUERY_PATH.GET_STORAGE_CATALOG_LIST}`, params).finally(() => {
        info.commit("setTableLoading", false);
      });

      if (data !== null) {
        return { data: data || {} };
      }

      return { data: {} };
    },
    async deleteElement(info, id: number | string) {
      try {
        await axios.delete(getFullPath(`${QUERY_PATH.GET_STORAGE_CATALOG_LIST}/${id}`));
        return true;
      } catch (e) {
        showNotification("Ошибка удаления элемента");
        return false;
      }
    },
    async saveTreeItem(info, { id, data }: { id: string | null; data: { type: string; parentNodeId: string | null; value: string } }) {
      try {
        const { data: result } = await axios[!id ? "post" : "put"](`${QUERY_PATH.GET_STORAGE_CATALOG_LIST}${!id ? "" : "/" + id}`, data);
        showNotification(!id ? "Элемент успешно добавлен" : "Элемент успешно изменён", NOTIFICATION_STATUS.SUCCESS);
        return result?.data || null;
      } catch (e) {
        showNotification(
          (e as unknown as error).response?.data?.error?.text ||
            (e as unknown as error).response?.data?.message ||
            (!id ? "Ошибка добавления элемента в справочник" : "Ошибка изменения справочника")
        );
        return null;
      }
    },
  },
  getters: {
    ...baseGetters,
    types(state: ITableState) {
      return state.libraries.types || [];
    },
    archives(state: ITableState) {
      return state.libraries.archives || [];
    },
    isTableLoading(state: ITableState) {
      return state.isTableLoading;
    },
  },
};
